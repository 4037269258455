// import * as React from "react";
import ArticleLayout from "../articles/article-layout";
import copyList from "./copyList";
import References from "../global/references.js";
import Parser from "html-react-parser";

import * as React from "react";
import { Component } from "react";

export default class Content extends Component {
  render() {
    console.log("show: ",`${this.props.show}`);
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <ArticleLayout
          page={this.props.page}
          copyList={copyList}
          ariaLabel="Group of people standing together"
          subheading={Parser("Addressing <br class='d-block d-md-none'/>Disparities in HIV")}
          heading="Engaging Historically Black Colleges and Universities on Health Equity to Help End the HIV Epidemic"
        />
        <References page={this.props.page} />
      </div>
    );
  }
}
